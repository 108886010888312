<script lang="ts" setup>
const title = ref('Ralph Controls')
const description = ref('My App Description')

useHead({
  title,
  meta: [
    {
      name: title,
      content: description,
    },
  ],
});

const { breadcrumbs } = useBreadcrumbs();

// Retrieve stores
const authStore: any = useAuthStore();
const notificationStore: any = useNotificationStore();

// Retrieve locales
const localePath = useLocalePath();
const { t } = useI18n();

// Retrieve role permissions
const isSystemAdmin = roleHasPermission(['system-admin'])
const isCustomerAdmin = roleHasPermission(['customer-admin'])
const isUser = roleHasPermission(['user'])
const isMechanic = roleHasPermission(['mechanic'])
const isInstaller = roleHasPermission(['installer'])

// Retrieve notification cookie
const notificationCookie = getCookie('dismissedNotificationId') ?? null

const links = [
  {
    label: t("sidebar.dashboard"),
    icon: "icon ralph-home-line",
    to: localePath("/dashboard"),
  }
]

if(isSystemAdmin){
  links.push(... [
    {
      label: t("sidebar.customers"),
      icon: "icon ralph-building",
      to: localePath("/dashboard/customers"),
    },
    {
      label: t("sidebar.sport_parks"),
      icon: "icon ralph-marker-pin",
      to: localePath("/dashboard/sport-parks"),
    },
    {
      label: t("sidebar.users"),
      icon: "icon ralph-users",
      to: localePath("/dashboard/users"),
    },
    {
      label: t("sidebar.activity_log"),
      icon: "icon ralph-activity",
      to: localePath("/dashboard/activity-log"),
    },
    {
      label: t("sidebar.customer_support"),
      icon: "icon ralph-message",
      to: localePath("/dashboard/customer-support"),
    },
    {
      label: t("sidebar.notifications"),
      icon: "icon ralph-notification",
      to: localePath("/dashboard/notifications"),
    },
    {
      label: t("sidebar.server_log"),
      icon: "icon ralph-server",
      to: localePath("/dashboard/server-log"),
    }
  ])
}

if(isCustomerAdmin){
  links.push(... [
    {
      label: t("sidebar.club"),
      icon: "icon ralph-building",
      to: localePath(`/dashboard/customers/${authStore.user.customer.id}`),
    },
    {
      label: t("sidebar.sport_parks"),
      icon: "icon ralph-marker-pin",
      to: localePath("/dashboard/sport-parks"),
    },
    {
      label: t("sidebar.users"),
      icon: "icon ralph-users",
      to: localePath("/dashboard/users"),
    },
    {
      label: t("sidebar.activity_log"),
      icon: "icon ralph-activity",
      to: localePath("/dashboard/activity-log"),
    },
    {
      label: t("sidebar.reports"),
      icon: "icon ralph-message",
      to: localePath("/dashboard/reports"),
    }
  ])
}

if(isUser){
  links.push(...[
    {
      label: t("sidebar.sport_parks"),
      icon: "icon ralph-marker-pin",
      to: localePath("/dashboard/sport-parks"),
    }
  ])
}

if(isUser && authStore.user.can_report_a_problem){
  links.push(...[
    {
      label: t("sidebar.reports"),
      icon: "icon ralph-message",
      to: localePath("/dashboard/reports"),
    }
  ])
}

if(isMechanic){
  links.push(...[
    {
      label: t("sidebar.sport_parks"),
      icon: "icon ralph-marker-pin",
      to: localePath("/dashboard/sport-parks"),
    },
    {
      label: t("sidebar.activity_log"),
      icon: "icon ralph-activity",
      to: localePath("/dashboard/activity-log"),
    },
    {
      label: t("sidebar.customer_support"),
      icon: "icon ralph-message",
      to: localePath("/dashboard/customer-support"),
    }
  ])
}

if(isInstaller){
  links.push(...[
    {
      label: t("sidebar.company"),
      icon: "icon ralph-building",
      to: localePath(`/dashboard/customers/${authStore.user.customer.id}`),
    },
    {
      label: t("sidebar.sport_parks"),
      icon: "icon ralph-marker-pin",
      to: localePath("/dashboard/sport-parks"),
    },
    {
      label: t("sidebar.users"),
      icon: "icon ralph-users",
      to: localePath("/dashboard/users"),
    },
    {
      label: t("sidebar.activity_log"),
      icon: "icon ralph-activity",
      to: localePath("/dashboard/activity-log"),
    },
    {
      label: t("sidebar.customer_support"),
      icon: "icon ralph-message",
      to: localePath("/dashboard/customer-support"),
    }
  ])
}
// Update windowWidth when the window is resized
const updateWindowWidth = () => {
  return showSidebar.value = window.innerWidth >= 768 ?? false
}

// Show sidebar variable
const showSidebar = ref(true)

watch(showSidebar, (value) => {
  // Watch showSidebar's value and remove scrolling from body when side bar is visible below 768 window width
  const bodyEL: HTMLElement = document.body

  if(value && window.innerWidth < 768) {
    bodyEL.classList.add('overflow-hidden')
  }else{
    bodyEL.classList.remove('overflow-hidden')
  }
})

// Attach the window resize event listener on component mount
onMounted(() => {
  updateWindowWidth()

  window.addEventListener('resize', updateWindowWidth)
})

// Remove the window resize event listener on component unmount
onBeforeUnmount(() => {
  window.removeEventListener('resize', updateWindowWidth)
})
</script>

<template class="h-full">
  <button 
    type="button"
    class="md:hidden relative top-2 left-0 w-10 cursor-pointer px-2 pb-6 pt-2 md:px-8" 
    @click="showSidebar = !showSidebar"
  >
    <div class="space-y-1">
      <div class="w-7 h-1 rounded-lg bg-[--color-primary-text]"></div>
      <div class="w-7 h-1 rounded-lg bg-[--color-primary-text]"></div>
      <div class="w-7 h-1 rounded-lg bg-[--color-primary-text]"></div>
    </div>
  </button>

  <div class="flex h-full max-w-screen-2xl m-auto relative transition-all duration-300" :class="{ 'md:pl-80' : showSidebar }">
    <sidebar :links="links" :showSidebar="showSidebar" @toggleSidebar="showSidebar = $event"/>

    <div class="pb-4 px-2 md:px-8 md:py-8 flex-1">
      <!-- Show when current active notification's id is not equal to notification cookie's value -->
      <NotificationsMessage 
        :notification="notificationStore.notification" 
        v-if="notificationStore.notification && notificationStore.notification.id != notificationCookie" 
      />
      
      <Breadcrumbs class="mb-4 md:mb-8" :breadcrumbs="breadcrumbs" />
      <slot />
    </div>
  </div>

  <UNotifications />
</template>


  